h1 {
    font-family: Dosis;
    font-style: normal;
    font-weight: normal;
    font-size: 2.5rem;
    line-height: 34px;
    display: flex;
    text-align: center;
    display: table-cell;
    color: #000000;

}

.contenedor-maximo {
    max-width: 1600px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    background: #fff;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;

}

.contenedor-maximo-2 {
    max-width: 1600px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    background: #fff;


}

.menu-position {
    height: 50px;
    bottom: 0;
    margin: auto;
    text-align: center;
}

h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */
    cursor: pointer;
    letter-spacing: 0.004em;
    padding: 0%;
    margin: 0%;
    /* Color/Primary */

    color: #00AAAD;
}

h3 {
    font-family: Dosis;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.0015em;

    color: #000000;
}

.mb-1 {
    margin-bottom: 0.25rem !important;
}

.itemDeparMobile {
    position: relative;
    background: rgba(0, 170, 173, 0.2);
    border-radius: 8px;


}

.label-telefonica {
    font-family: Dosis;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.0015em;
    text-transform: capitalize;
    color: #00AAAD;
}

.image-tele {
    position: absolute;
    top: -8px;
    bottom: 0;
    left: -17px;
}

.mt-1 {
    margin-top: 1.25rem !important;
}

.divDetalleDepar {
    display: flex;
    flex-direction: row;
    padding: 1rem;
}

.btn-boton-borrar {

    border: 1px solid #A1AAB2;
    box-sizing: border-box;
    border-radius: 25px;
    cursor: pointer;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */
    width: 50%;
    letter-spacing: 0.0125em;

    /* Color/OnSecundary */

    color: #003031;
}

.btn-boton-borrar:hover {
    color: #003031;
    text-decoration: none;
}


.btn-boton-buscar {
    background: #132A3E;
    border-radius: 25px;
    cursor: pointer;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */
    width: 50%;

    letter-spacing: 0.0125em;

    /* Color/Surface */

    color: #FFFFFF;
}

.btn-boton-buscar:hover {
    color: #FFFFFF;
    text-decoration: none;
}

.btn-boton-guardar {
    background: #175a11;
    border-radius: 25px;
    cursor: pointer;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */

    letter-spacing: 0.0125em;

    /* Color/Surface */

    color: #FFFFFF;
}

.btn-boton-download {
    background: #00AAAD;
    border-radius: 25px;
    cursor: pointer;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */

    letter-spacing: 0.0125em;

    /* Color/Surface */

    color: #FFFFFF;
}

.btn-boton-guardar:hover {
    color: #FFFFFF;
    text-decoration: none;
}

.btn-boton-download:hover {
    color: #FFFFFF;
    text-decoration: none;
}

.btn-boton-cerrar {
    background: #132A3E;
    cursor: pointer;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */

    letter-spacing: 0.0125em;

    /* Color/Surface */

    color: #FFFFFF;
}

.btn-boton-cerrar:hover {
    color: #FFFFFF;
    text-decoration: none;
}

.btn-boton-llegar {
    background: #132A3E;
    border-radius: 25px;
    cursor: pointer;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */
    letter-spacing: 0.0125em;

    /* Color/Surface */

    color: #FFFFFF;
}

.btn-boton-llegar:hover {
    color: #FFFFFF;
    text-decoration: none;
}


.card-tel-title {

    font-family: Dosis;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.0015em;
    color: #003031;

}

.card-tel-subtitle {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.004em;
    color: #425565;
}

.btn-boton-filtro-linea {

    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */

    letter-spacing: 0.0125em;

    /* Color/OnPrimary */

    color: #FFFFFF;

}

.btn-boton-filtro-linea:hover {
    color: #FFFFFF;
    text-decoration: none;
}

.listado-lineas {
    height: 70vh;
    overflow-y: scroll;
}

.filtro-linea {
    display: flex;
    flex-direction: row;
    justify-content: center;

    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 20%;
    padding: 0;

    background: #132A3E;
    box-shadow: 0px 4px 16px rgba(19, 42, 62, 0.35);
    border-radius: 25px;
    z-index: 300;

}

.input-label-d {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #003031;
    text-transform: capitalize;
    padding-right: 1rem;
}

.label-nombre-organizacion {

    font-family: Dosis;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    /* identical to box height */

    letter-spacing: 0.0015em;

    /* Color/PrimaryVariant */

    color: #007681;
}

.label-telefono {

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.0025em;

    /* Color/OnSecundary */

    color: #003031;
}

.label-horario {

    font-family: Roboto;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    letter-spacing: 0.005em;

    /* Color/OnSecundary */

    color: #003031;
}

.info-title {
    font-family: Dosis;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.0015em;

    /* Color/OnSecundary */

    color: #003031;
    text-transform: capitalize;

}

.modal.right.fade .modal-dialog {
    height: 100%;
    overflow-y: auto;
    left: 37%;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
}



.leaflet-container {
    width: 65%;
    height: 100%;
}

.select-custom-class {
    margin: 5px;
}

.punto-label-2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.0125em;

    /* Color/OnPrimary */
    cursor: pointer;
    color: #FFFFFF;
}

@media only screen and (min-device-width: 200px) and (max-device-width: 990px) {
    .modal-backdrop.fade {
        opacity: 0;
    }

    .grid-absolute {
        z-index: 400;
        padding: 0;
        max-height: 80vh !important;
    }

    .grid-resultados {
        padding: 20px 50px !important;
    }

    #mymodal{
        display: none !important;
    }

    .modal-backdrop{
        display: none !important;;
    }
    
    

}


.result-label-title {
    font-family: Dosis;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    /* identical to box height */

    letter-spacing: 0.0015em;

    /* Color/OnSecundary */

    color: #003031;
    margin-bottom: 10px;
}

.result-label-1 {
    margin-left: 10px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.0025em;

    /* Color/OnSecundary */

    color: #003031;
}

.result-label-2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */

    letter-spacing: 0.0125em;

    /* Color/SecundaryVariant */

    color: #902857;

}

.punto-label-2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.0125em;

    /* Color/OnPrimary */
    cursor: pointer;
    color: #FFFFFF;
}

.punto-label-3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.0025em;
    padding-left: 10.5px;
    /* Color/OnSecundary */

    color: #003031;
}

.punto-label-4 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.0025em;

    /* Color/OnPrimary */

    color: #FFFFFF;
    text-transform: uppercase;

}

.punto-label-5 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.0025em;
    padding: 10px;
    /* Color/OnSecundary */

    color: #003031;
}

.punto-label-6 {
    /* Subtitle1/Bold */

    font-family: Dosis;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    /* identical to box height */

    letter-spacing: 0.0015em;

    /* Color/PrimaryVariant */

    color: #007681;
}

.punto-label-7 {
    position: static;
    width: 81px;
    height: 28px;
    left: 16px;
    top: 12px;

    /* H6/Bold */

    font-family: Dosis;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    /* identical to box height */

    letter-spacing: 0.0015em;

    /* Color/PrimaryVariant */

    color: #007681;
}

.service-label-1 {
    font-family: Dosis;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.0015em;
    /* Color/PrimaryVariant */

    color: #007681;
}

.service-label-2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.0025em;
    /* Color/OnSecundary */

    color: #003031;
}

.service-label-3 {

    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.0025em;

    /* Color/OnSecundary */

    color: #003031;


}

.service-label-4 {

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.0025em;

    /* Color/OnSecundary */

    color: #003031;


}

.filtro-absoluto {
    z-index: 400;
    height: 80vh;
    padding: 0;
}